<div class="card">
  <div class="card-header">
    <div class="title">
      <img src="/core/assets/images/totem2_135.svg" alt="Totem 135" class="logo"/>
      <p>August 1, 2024</p>
      <p class="header-topic">NLQ & PromptFactory</p>
    </div>
    <div class="link-section">
      <a href="https://pienso.atlassian.net/wiki/spaces/usermanual/pages/2916122625/Explore" target=”_blank” class="read-more">Read more</a>
    </div>
    
  </div>
  <div class="card-body">
    <p>
      We've added beta versions of two new features to <span>Pienso Explore</span>. Use
      <span>NLQ</span> to ask your documents questions. And <span>PromptFactory</span> lets you 
      run customizable summaries on your documents.
    </p>
  </div>
</div>

